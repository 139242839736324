<template>
  <p class="error-contact text-body">
    Want to get in touch?
    <span v-focusable class="error-contact__link" @click="openContactUsWidget"
      >Contact Us</span
    >
    and help make Forexlive even better by letting us know about the bad link.
  </p>
</template>

<script>
import { MODAL } from 'modals/AModalWrapper'

export default {
  name: 'AErrorContact',
  methods: {
    openContactUsWidget() {
      this.$helper.openModal(MODAL.CONTACT_US)
    }
  }
}
</script>

<style lang="scss" scoped>
.error-contact {
  @include mobile {
    text-align: center;
  }

  &__link {
    color: $c--main;
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
  }
}
</style>
